import { Injectable } from '@angular/core';
import { IonTabs } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';

@Injectable({
    providedIn: 'root'
})
export class TabsService {

    private _tabs: IonTabs;

    constructor(private logger: NGXLogger) { }

    get tabs(): IonTabs {
        return this._tabs;
    }

    get isTabsRegistered(): boolean {
        return !!this._tabs;
    }

    registerTabsComponent(tabs: IonTabs) {
        if (tabs)
            this._tabs = tabs;
        else
            this.logger.warn("Attempted registration of null tabs component");
    }

    unregisterTabsComponent() {
        this._tabs = undefined;
    }

}
