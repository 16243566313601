export const EV_TOPIC_OAUTH = 'srv:oauth';
export const EV_TOPIC_LOGIN = 'srv:login';
export const EV_TOPIC_SCREEN = 'srv:plf:screen';
export const EV_TOPIC_URL = 'srv:plf:url';
export const EV_TOPIC_CONN = 'srv:plf:conn';
export const EV_TOPIC_PICSRV = 'srv:pic';
export const EV_TOPIC_PROPSRV = 'srv:prop';
export const EV_TOPIC_TM = 'srv:tm';
export const EV_TOPIC_ACCT = 'srv:acct';

export enum EventType {
    // global events
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    OAUTH_SUCCESS,
    OAUTH_FAILURE,
    SCREEN_RESIZE,
    SCREEN_DARKMODE,
    URL_CHANGE,
    CONNECTIVITY_CHANGE,
    PIC_DATA,
    PIC_ADD,
    PIC_DELETE,
    COORD_CHANGE,
    TM_TIME_CHANGE,
    ACCT_STATS_AVAIL,
    ACCT_STATS_CHANGE
};

export interface IRuntimeEvent {
    readonly type: EventType;
    readonly data?: any;
}