import { Injectable, NgZone } from '@angular/core';
import { environment } from '../../environments/environment';
import { NGXLogger } from 'ngx-logger';
import { LoginsMap } from 'aws-sdk/clients/cognitoidentity';
import * as AWS from 'aws-sdk/global';
import * as AWSSrv from "aws-sdk/lib/service";
import { CognitoIdentityCredentials } from 'aws-sdk/global';

@Injectable({
    providedIn: 'root'
})
export class AWSService {

    private readonly COGNITO_TOKEN_URL = 'cognito-idp.' + environment.awsRegion.toLowerCase() + '.amazonaws.com/' + environment.awsUserPoolId;
    private readonly apiGwClientFactory = require('aws-api-gateway-client').default;
    private apiGwClient: any;

    constructor(private logger: NGXLogger, private ngZone: NgZone) {
        AWS.config.region = environment.awsRegion;
    }

    getAWSCredentialsStatus(onValid: (creds: AWS.CognitoIdentityCredentials) => void, onInvalid: () => void) {
        let creds = <AWS.CognitoIdentityCredentials>(AWS.config.credentials);
        if (!creds)
            onInvalid();
        else
            creds.get((err) => {
                if (err)
                    onInvalid();
                else {
                    this.setUpApiGW(creds);
                    onValid(creds);
                }
            });
    }

    updateAWSIdentityCredentials(
        idToken: string,
        onSuccess: (creds: AWS.CognitoIdentityCredentials) => void,
        onFailure: (err) => void
    ) {
        let logins: LoginsMap = {};
        logins[this.COGNITO_TOKEN_URL] = idToken;
        const params = {
            IdentityPoolId: environment.awsIdentityPoolId,
            Logins: logins
        };
        let creds = new AWS.CognitoIdentityCredentials(params, <AWSSrv.ServiceConfigurationOptions>{});

        creds.clearCachedId();

        AWS.config.credentials = creds;
        creds.get((err) => {
            if (err) {
                this.logger.error(err);
                onFailure(err);
            } else {
                this.setUpApiGW(creds);
                onSuccess(creds);
            }
        });
    }

    private setUpApiGW(creds: CognitoIdentityCredentials) {
        this.apiGwClient = this.apiGwClientFactory.newClient({
            invokeUrl: environment.apiGwUrl,
            accessKey: creds.accessKeyId,
            secretKey: creds.secretAccessKey,
            sessionToken: creds.sessionToken,
            region: environment.awsRegion,
            host: environment.apiGwHost,
            retries: 20,
            retryCondition: (err) => err.response && err.response.status === 504
        });
    }

    clearAWSCredentials() {
        this.apiGwClient = undefined;
        AWS.config.credentials = null;
    }

    apiGwMakeRequest(
        method: string,
        path: string,
        headers: object,
        queryParams: object,
        body: any,
        onSuccess: (result, headers?) => void,
        onFailure: (err) => void
    ) {
        var pathTemplate = path;
        var pathParams = {};
        var additionalParams = {
            headers: headers ? headers : {},
            queryParams: queryParams ? queryParams : {}
        };
        var body = body ? body : {};
        this.ngZone.runOutsideAngular(() => this.apiGwClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
            .then(result => this.ngZone.run(() => onSuccess(result.data ? result.data : {}, result)))
            .catch(err => this.ngZone.run(() => onFailure(err))));
    }

    apiGwMakeRequestRaw(
        method: string,
        path: string,
        headers: object,
        queryParams: object,
        body: any,
        onSuccess: (result) => void,
        onFailure: (err) => void
    ) {
        var pathTemplate = path;
        var pathParams = {};
        var additionalParams = {
            headers: headers ? headers : {},
            queryParams: queryParams ? queryParams : {}
        };
        var body = body ? body : {};
        this.ngZone.runOutsideAngular(() => this.apiGwClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
            .then(result => this.ngZone.run(() => onSuccess(result)))
            .catch(err => this.ngZone.run(() => onFailure(err))));
    }

}

