export enum CacheId {
    PROPS = 1,
    LEASES,
    CONTS,
    PROPSUMS,
    LEASUMS,
    CONTSUMS,
    THUMBS,
    PROP_PICS,
    AMENS,
    AMENSUMS,
    DOCS,
    DOCSUMS,
    REMS,
    REMSUMS,
    MSGS,
    MSGSUMS,
    REVS,
    SUBSCRIBER,
    SUBSCRIPTION,
    INVS,
    GMETRICS,
    PMETRICS,
    PSTATS
};

export interface ICache<K, T> {
    clear();
    storeItem(key: K, item: T);
    hasKey(key: K): boolean;
    getItem(key: K): T;
    getItems(): T[];
    deleteItem(key: K);
}

export class MemoryCache<K, T> implements ICache<K, T> {

    private readonly maxItems: number;
    private readonly ttlMillis: number;
    private byUsage: CacheItem<K, T>[];
    private byKey: Map<K, CacheItem<K, T>>;

    constructor(maxItems?: number, ttlMinutes?: number) {
        if (maxItems)
            this.maxItems = maxItems;
        if (ttlMinutes)
            this.ttlMillis = ttlMinutes * 60000;
        this.byUsage = [];
        this.byKey = new Map<K, CacheItem<K, T>>();
    }

    clear() {
        this.byUsage = [];
        this.byKey.clear();
    }

    storeItem(key: K, item: T) {
        let itm = this.byKey.get(key);
        if (itm) {
            itm.item = item;
            itm.timeMillis = Date.now();
            this.makeMostRecentyUsed(itm);
        } else {
            itm = { key: key, item: item, timeMillis: Date.now() };
            this.byUsage.push(itm);
            this.byKey.set(key, itm);
            if (this.maxItems && this.byUsage.length > this.maxItems) {
                this.purgeExpiredItems();
                if (this.byUsage.length > this.maxItems) {
                    let oldestUsed = this.byUsage.splice(0, 1)[0];
                    this.byKey.delete(oldestUsed.key);
                }
            }
        }
    }

    hasKey(key: K): boolean {
        let itm = this.byKey.get(key);
        if (!itm)
            return false;
        if (!this.ttlMillis)
            return true;
        return (itm.timeMillis >= Date.now() - this.ttlMillis);
    }

    getItem(key: K): T {
        let itm = this.byKey.get(key);
        if (!itm)
            return undefined;
        if (!this.ttlMillis || itm.timeMillis >= Date.now() - this.ttlMillis) {
            this.makeMostRecentyUsed(itm);
            return itm.item;
        }
        return undefined;
    }

    getItems(): T[] {
        return this.byUsage.map<T>(itm => itm.item);
    }

    deleteItem(key: K) {
        let itm = this.byKey.get(key);
        if (itm) {
            let idx = this.byUsage.findIndex(i => i == itm);
            this.byUsage.splice(idx, 1);
            this.byKey.delete(key);
        }
    }

    private makeMostRecentyUsed(item: CacheItem<K, T>) {
        let idx = this.byUsage.findIndex(itm => itm == item);
        if (idx < this.byUsage.length - 1) {
            this.byUsage.splice(idx, 1);
            this.byUsage.push(item);
        }
    }

    private purgeExpiredItems() {
        if (!this.ttlMillis)
            return;
        let cutoff = Date.now() - this.ttlMillis;
        let valid: CacheItem<K, T>[] = [];
        this.byUsage.forEach(itm => {
            if (itm.timeMillis >= cutoff)
                valid.push(itm);
            else
                this.byKey.delete(itm.key);
        });
        this.byUsage = valid;
    }

}

interface CacheItem<K, T> {
    key: K;
    item: T;
    timeMillis: number;
}