import { LatLngExpression } from 'leaflet';
import { RuntimeError } from "../../model/errors.model";

export class CoordData {

    static fromAttValue(attVal: string): CoordData {
        if (!attVal || attVal.length < 6)
            throw new RuntimeError('BadArg', `Invalid coord attribute value (${attVal})`);
        let split = attVal.split("@");
        let split2 = split[1].split(",");
        return new CoordData(split[0] == 'U', Number(split2[0]), Number(split2[1]));
    }

    constructor(
        public isSetByUser: boolean,
        public lat: number,
        public long: number
    ) { }

    asAttValue(): string {
        return `${this.isSetByUser ? 'U' : 'G'}@${this.lat},${this.long}`;
    }

    asDisplayValue(): string {
        return `${this.lat} , ${this.long}`;
    }

    asLatLngExpression(): LatLngExpression {
        return [this.lat, this.long];
    }

}

export interface GeolocationResult {
    label: string;
    coord: CoordData;
}

export interface MapConf {
    label: string,
    coord: CoordData,
    zoomLvl: number,
    showMarker: boolean
}

