import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { catchError } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private logger: NGXLogger) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError(err => {
                this.logger.warn('HTTPInterceptorHandler called!!!');
                this.logger.error(err);
                return throwError(() => err);
            }));

    }

}