import { ErrorHandler, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private logger: NGXLogger/*, private notifier: NotificationService*/) { }

    handleError(error: Error | HttpErrorResponse) {
        this.logger.warn('GlobalErrorHandler called!!!');
        if (error instanceof HttpErrorResponse) {
            // Server Error
        } else {
            // Client Error
            this.logger.error(error);
        }
    }

}