export interface RawElemTemplate {
    readonly id: number;
    readonly cls: string;
    readonly kind: number;                                          // Element kind id
    readonly valid: boolean;                                        // Valid for entering new data
    readonly attrCatOrder: number[];                                // Ordered list of allowed attr category ids
    readonly attrTpls: number[];
    readonly docTpls?: number[];                                    // [documentTplIds]
    readonly catId?: number;
}

export interface RawAttrTemplate {
    readonly id: number;
    readonly cls: string;
    readonly catId: number;
    readonly valid: boolean;                                        // Valid for entering new data
    readonly order: number;                                         // Attr order inside its category
    readonly type: number;                                          // Data type id
    readonly maxLen?: number;                                       // Max length of value in characters
    readonly units?: string[];                                      // Allowed unit of measurement label ids (only for numeric fields)
    readonly choices?: string[];                                    // Allowed choices for multiple choice attrs
    readonly required: boolean;
    readonly history: boolean;
    readonly docTpls?: number[];                                    // ids of source documents
}

export interface RawTemplateSpec {
    readonly elemTpls: { [tpltId: number]: RawElemTemplate };
    readonly attrTpls: { [tpltId: number]: RawAttrTemplate };
}

export class ElemTemplate {
    constructor(private rawTpl: RawElemTemplate) { }

    get id(): number {
        return this.rawTpl.id;
    }

    get nameId(): string {
        return 'tpl.' + this.rawTpl.id;
    }

    get kind(): number {
        return this.rawTpl.kind;
    }

    get catId(): number {
        return this.rawTpl.catId ? this.rawTpl.catId : -1;
    }

    get isValid(): boolean {
        return this.rawTpl.valid;
    }

    get catIdsOrdered(): number[] {
        return this.rawTpl.attrCatOrder;
    }

    get docTplIds(): number[] {
        return this.rawTpl.docTpls ? this.rawTpl.docTpls : [];
    }
}

export class AttrTemplate {
    constructor(private rawTpl: RawAttrTemplate) { }

    get id(): number {
        return this.rawTpl.id;
    }

    get cls(): string {
        return this.rawTpl.cls;
    }

    get nameId(): string {
        return 'tpl.' + this.rawTpl.id;
    }

    get catId(): number {
        return this.rawTpl.catId;
    }

    get catNameId(): string {
        return 'cat.' + this.rawTpl.catId;
    }

    get order(): number {
        return this.rawTpl.order;
    }

    get type(): number {
        return this.rawTpl.type;
    }

    get maxLen(): number {
        return this.rawTpl.maxLen;
    }

    get unitIds(): string[] {
        return this.rawTpl.units;
    }

    get choices(): string[] {
        return this.rawTpl.choices;
    }

    get isValid(): boolean {
        return this.rawTpl.valid;
    }

    get isRequired(): boolean {
        return this.rawTpl.required;
    }

    get hasHistory(): boolean {
        return this.rawTpl.history;
    }

    get docTpls(): number[] {
        return this.rawTpl.docTpls;
    }
}

export class TemplateSpec {
    constructor(private rawSpec: RawTemplateSpec) { }

    getElemTplsByKind(kind: number, validOnly: boolean): ElemTemplate[] {
        return Object.values(this.rawSpec.elemTpls).filter(rawTpl => rawTpl.kind == kind && (!validOnly || rawTpl.valid)).map<ElemTemplate>(rawTpl => new ElemTemplate(rawTpl));
    }

    getElemTplById(tplId: number): ElemTemplate {
        const rawTpl = this.rawSpec.elemTpls[tplId];
        return rawTpl ? new ElemTemplate(rawTpl) : undefined;
    }

    getAttrTplById(tplId: number): AttrTemplate {
        const rawTpl = this.rawSpec.attrTpls[tplId];
        return rawTpl ? new AttrTemplate(rawTpl) : undefined;
    }

    getAttrTplsForElemTpl(elemTplId: number, validOnly: boolean): AttrTemplate[] {
        let elemTpl = this.rawSpec.elemTpls[elemTplId];
        if (!elemTpl)
            return [];
        let result = elemTpl.attrTpls
            .map<AttrTemplate>(tplId => this.getAttrTplById(tplId));
        return validOnly ? result.filter(attTpl => attTpl.isValid) : result;
    }

    getDocTplsForElemTpl(elemTplId: number, validOnly: boolean): ElemTemplate[] {
        let elemTpl = this.rawSpec.elemTpls[elemTplId];
        if (!elemTpl)
            return [];
        let result = elemTpl.docTpls ? elemTpl.docTpls.map<ElemTemplate>(tplId => this.getElemTplById(tplId)) : [];
        return validOnly ? result.filter(docTpl => docTpl.isValid) : result;
    }

    getAttrTplByClass(cls: string, validOnly: boolean): AttrTemplate {
        let attTpls = validOnly ? (Object.values(this.rawSpec.attrTpls)).filter(tpl => tpl.valid) : Object.values(this.rawSpec.attrTpls);
        let rawTpl = attTpls.find(tpl => tpl.cls == cls);
        return rawTpl ? new AttrTemplate(rawTpl) : undefined;
    }

}

