import { TabsService } from './services/tabs.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { LocaleService } from './services/locale.service';
import { TimeMachineService } from './services/time-machine.service';
import { Router } from '@angular/router';
import { ModalService } from './services/modal.service';

@Component({
    selector: 'tm-view',
    templateUrl: 'tm-view.html',
    styleUrls: ['./tm-view.scss']
})
export class TimeMachineView implements OnInit, OnDestroy {

    isTmPresent: boolean;
    minYear: number;
    maxYear: number;
    valYear: number;
    valMonth: number;
    maxDay: number;
    valDay: number;
    tmTenseLbl: string;
    tmDateFmtd: string;

    // model
    private touched: boolean;
    private intervalId: NodeJS.Timeout;

    private _offsetDays: number;
    private _year: number;
    private _month: number;
    private _day: number;

    constructor(
        private srvMod: ModalService,
        private srvTime: TimeMachineService,
        private nav: NavController,
        private router: Router,
        private srvTabs: TabsService,
        private srvLoc: LocaleService
    ) { }

    ngOnInit(): void {
        this.touched = false;
        this.initModel(this.srvTime.offsetDays);
        this.updateUI();
        this.intervalId = setInterval(() => {
            if (this.touched)
                return;
            this.initModel(this.srvTime.offsetDays);
            this.updateUI();
        }, 5000);
    }

    onClose() {
        if (this.touched) {
            this.srvTime.changeOffsetDays(this._offsetDays);
            this.srvMod.dismissModal({}, () => {
                let url = `/priv/tabs/${this.srvTabs.tabs.getSelected()}`;
                if (this.router.url == url)
                    location.reload();
                else
                    this.nav.navigateRoot(url);
            });
        } else
            this.srvMod.dismissModal();
    }

    onReset() {
        this.touched = true;
        this.initModel(0);
        this.updateUI();
    }

    onYearChange(ev) {
        if (ev.detail.value == this._year)
            return;
        this.touched = true;
        this._year = ev.detail.value;
        if (this._day == 29 && this._month == 1 && !this.isLeapYear(this._year))
            this._day = 28;
        this.updateOffsetDays();
        this.updateUI();
    }

    onMonthChange(ev) {
        if (ev.detail.value == this._month)
            return;
        this.touched = true;
        this._month = ev.detail.value;
        let newMaxDay = this.getMonthDays(this._year, this._month);
        if (this._day > 28 && this._day > newMaxDay)
            this._day = newMaxDay;
        this.updateOffsetDays();
        this.updateUI();
    }

    onDayChange(ev) {
        if (ev.detail.value == this._day)
            return;
        this.touched = true;
        this._day = ev.detail.value;
        this.updateOffsetDays();
        this.updateUI();
    }

    ngOnDestroy(): void {
        clearInterval(this.intervalId);
    }

    private initModel(offsetDays: number) {
        this._offsetDays = offsetDays
        let nowLocal = new Date();
        nowLocal.setDate(nowLocal.getDate() - this._offsetDays);
        this._year = nowLocal.getFullYear();
        this._month = nowLocal.getMonth();
        this._day = nowLocal.getDate();
    }

    updateOffsetDays() {
        let nowLocal = new Date();
        let newLocal = new Date(this._year, this._month, this._day, nowLocal.getHours(), nowLocal.getMinutes(), nowLocal.getSeconds());
        this._offsetDays = Math.floor((nowLocal.getTime() - newLocal.getTime() + Math.abs(nowLocal.getTimezoneOffset() - newLocal.getTimezoneOffset()) * 60000) / 86400000);
    }

    private updateUI() {
        this.isTmPresent = this._offsetDays == 0;
        let y = new Date().getFullYear();
        this.minYear = y - 50;
        this.maxYear = y + 2;
        this.valYear = this._year;
        this.valMonth = this._month;
        this.maxDay = this.getMonthDays(this._year, this._month);
        this.valDay = this._day;
        this.tmTenseLbl = this.isTmPresent ? "tm.present" : this._offsetDays > 0 ? "tm.past" : "tm.future";
        this.tmDateFmtd = `${this._day} ${this.srvLoc.monthNamesShort[this._month]} ${this._year}`;
    }

    private getMonthDays(year: number, month: number): number {
        if (month == 1)
            return this.isLeapYear(year) ? 29 : 28;
        return (month == 3 || month == 5 || month == 8 || month == 10) ? 30 : 31;
    }

    private isLeapYear(year: number): boolean {
        return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
    }

}