import { Injectable } from '@angular/core';
import { NGXLogger, NgxLoggerLevel, INGXLoggerMonitor } from 'ngx-logger';

@Injectable({
    providedIn: 'root'
})
export class LogMonitorService implements INGXLoggerMonitor {

    static readonly MIN_LEVEL = NgxLoggerLevel.DEBUG;

    private log: any[] = [];

    constructor(private logger: NGXLogger) { }

    record() {
        this.logger.registerMonitor(this);
    }

    onLog(e) {
        if (e.level < LogMonitorService.MIN_LEVEL)
            return;
        let idx = this.log.findIndex(entry => e.timestamp >= entry.timestamp);
        if (idx >= 0)
            this.log.splice(idx, 0, e);
        else
            this.log.push(e);
        if (this.log.length > 30)
            this.log.splice(0, 1);
    }

    getLogStr(): string {
        return JSON.stringify(this.log);
    }

}
