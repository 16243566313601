export const AS_TRIALLING = "TRIALING";
export const AS_PMT_DUE = "PMT_DUE";
export const AS_ACTIVE = "ACTIVE";
export const AS_CANCELLED = "CANCELLED";
export const AS_CLOSED = "CLOSED";

export const PS_PROCESSING = "PROCESSING";
export const PS_FAILED = "FAILED";

export const E_ACCT_NOT_FOUND = "InvalidUserIdentity";
export const E_ACCT_CLOSED = "AccountClosed";
export const E_ACCT_DISABLED = "AccountDisabled";

export const E_PMT_AUTH_REQUIRED = "ERR_AUTH_REQUIRED";

export enum AccessLevelId {
    UNKNOWN = -1,
    RED,
    YELLOW,
    GREEN
}

export enum WarnId {
    AGREE_TERMS = 0,
    ACCT_DETAILS,
    PMT_DETAILS,
    PMT_DECLINED
}

export const WARN_FIX_URL = [
    "/priv/tabs/settings/legal",
    "/priv/tabs/settings/account",
    "/priv/tabs/settings/subpay",
    "/priv/tabs/settings/subpay"
];

export type WarningStats = [number, number, number, number];        // [AGREE_TERMS_TMSTP, ACCT_DETAILS_TMSTP, PMT_DETAILS_TMSTP, PMT_DECLINED_TMSTP]

export interface RawAccountStats {
    readonly timestamp: number;
    readonly accountState: string;
    readonly pmtErrorCode?: string;
    readonly pmtErrorData?: string;
    readonly accessLevel: number;
    readonly stateTimeout: number;
    readonly unreadMessages: number;
    readonly warnings: WarningStats;
}

export interface IAccountStats {
    readonly timestamp: number;
    readonly isStateUnknown: boolean;
    readonly isStateTrialing: boolean;
    readonly isStateActive: boolean;
    readonly isStatePmtDue: boolean;
    readonly isStateClosed: boolean;
    readonly isStateCancelled: boolean;
    readonly pmtErrorCode: string;
    readonly pmtErrorData: string;
    readonly accessLevel: number;
    readonly stateTimeoutDate: Date;
    readonly stateTimeoutSecs: number;
    readonly unreadMessages: number;
    hasWarnings(): boolean;
    hasWarning(warnId: WarnId): boolean;
    isWarnTermImminent(warnId: WarnId): boolean;
    getWarnTermDate(warnId: WarnId): Date;
}

export interface RawAddressData {
    addressLine1: string;
    city: string;
    postalCode: string;
    state?: string;
    country: string;
}

export interface RawSubscriberData {
    isCompany: boolean;
    companyName?: string;
    companyVatId?: string;
    companyRegNumber?: string;
    bankName?: string;
    bankSWIFT?: string;
    bankIBAN?: string;
    givenName: string;
    familyName: string;
    email: string;
    phoneNumber: string;
    address: RawAddressData;
    idCardNumber?: string;
    personalNumber?: string;
}

export interface RawSubscription {
    subscriptionId?: number;
    subscriptionName?: string;
    paymentMethodId?: string;
    paymentMethodName?: string;
}
