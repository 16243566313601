import { LoggedInGuard } from './services/loggedin.guard';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes, RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { GlobalErrorHandler } from './handlers/errhandler.handler';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpErrorInterceptor } from './handlers/http-error.interceptor';
import { LTransLoader } from './handlers/translation.handler';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TimeMachineView } from './tm-view';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as L from 'leaflet';

L.Icon.Default.imagePath = 'assets/leaflet/';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/priv/tabs/properties',
    pathMatch: 'full'
  }, {
    path: 'priv',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('./privileged/privileged.module').then(m => m.PrivilegedModule)
  }, {
    path: 'pub',
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
  }, {
    path: '**',
    redirectTo: '/pub/page-not-found'
  }
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      backButtonText: ''/*,
      backButtonIcon: '/assets/img/back-icon.svg'*/
    }),
    RouterModule.forRoot(routes, {
      enableTracing: false // <-- debugging purposes only
      ,
      relativeLinkResolution: 'legacy'
    }),
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    }),
    LoggerModule.forRoot({
      level: environment.production ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.INFO,
      disableConsoleLogging: false,
      enableSourceMaps: true,
      serverLogLevel: NgxLoggerLevel.OFF
    }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: LTransLoader, deps: [HttpClient] }
      /*      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: LeaslerMissingTranslationHandler },
            useDefaultLang: false*/
    }),
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    AppComponent,
    TimeMachineView
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
