import { HttpClient } from '@angular/common/http';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader } from '@ngx-translate/core';
import { Observable, of, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

export class LTransLoader implements TranslateLoader {
    readonly FILE_PATH = '/assets/i18n/';
    readonly MAIN_FILE_PREFIX = '';
    readonly TPLS_FILE_PREFIX = 'tpl-strings-';
    readonly FILE_SUFFIX = '.json';

    constructor(private http: HttpClient) { }

    public getTranslation(lang: string): Observable<Object> {
        return forkJoin<[Object, Object]>([
            this.http.get<Object>(`${this.FILE_PATH}${this.MAIN_FILE_PREFIX}${lang}${this.FILE_SUFFIX}`),
            this.http.get<Object>(`${this.FILE_PATH}${this.TPLS_FILE_PREFIX}${lang}${this.FILE_SUFFIX}`)
        ]).pipe(
            map<[Object, Object], Object>(results => {
                Object.assign(results[0], results[1]);
                return results[0];
            })
        );
    }
}

export class LMissingTransHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams): Observable<string> {
        return of(params.key);
    }
}


