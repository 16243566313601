<ion-app>
  <ng-container *ngIf="platformState == S_RDY; else waiting">
    <ion-header [hidden]="!srvPlatform.url.startsWith('/priv')">
      <ion-toolbar class="main-bar" mode="md">
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title slot="start">Leasler</ion-title>
        <ion-buttons id="status" mode="ios" slot="end">
          <!--
        <ion-button shape="round">
          <ion-icon name="warning"></ion-icon>
        </ion-button>
      -->
          <ion-button shape="round" [disabled]="tmBtnDisabled" (click)="onShowTimeMachine()">
            <ion-icon [class.pulse]="!isTmInPresent" mode="md" src="assets/img/time-machine.svg"></ion-icon>
          </ion-button>
          <!--
          <ion-button *ngIf="srvPlatform.online; else offline" shape="round" (click)="onCloudClick()">
            <ion-icon mode="md" name="cloud-outline"></ion-icon>
          </ion-button>
          <ng-template #offline>
            <ion-button shape="round">
              <ion-icon mode="md" name="cloud-offline-outline"></ion-icon>
            </ion-button>
          </ng-template>-->
        </ion-buttons>
      </ion-toolbar>
      <!--
    <div id="crumbs" class="ion-align-items-center ion-justify-content-between">
      <ion-label color=" dark">These > Are > Bread > Crumbs</ion-label>
    </div>
  -->
    </ion-header>

    <ion-content>
      <ion-router-outlet [swipeGesture]="false">
      </ion-router-outlet>
    </ion-content>
  </ng-container>
  <ng-template #waiting>
    <ion-spinner></ion-spinner>
  </ng-template>
</ion-app>