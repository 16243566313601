import { v4 } from 'uuid';

export const AUTH_COGNITO = "Cognito";
export const AUTH_OAUTH = "OAuth";

export const OAUTH_PROV_FACEBOOK = 'Facebook';
export const OAUTH_PROV_GOOGLE = 'Google';

export interface ILoginCredentials {
    email: string;
    password: string;
}

export interface IOAuthDescriptor {
    readonly timestamp: number;
    readonly provider: string;
    readonly authId: string;
}

export class OAuthDescriptor implements IOAuthDescriptor {

    private static readonly KEY_OAUTH_DESCRIPTOR = "leasler.oauth";

    static load(): OAuthDescriptor {
        const descTxt = localStorage.getItem(OAuthDescriptor.KEY_OAUTH_DESCRIPTOR);
        if (!descTxt)
            return undefined;

        return new OAuthDescriptor(JSON.parse(descTxt).descriptor);
    }

    static create(providerId: string): OAuthDescriptor {
        return new OAuthDescriptor({
            timestamp: Date.now(),
            provider: providerId,
            authId: v4()
        });
    }

    static store(descriptor: IOAuthDescriptor) {
        if (descriptor)
            localStorage.setItem(OAuthDescriptor.KEY_OAUTH_DESCRIPTOR, JSON.stringify(descriptor));
        else
            localStorage.removeItem(OAuthDescriptor.KEY_OAUTH_DESCRIPTOR);
    }

    private constructor(private descriptor: IOAuthDescriptor) { }

    get timestamp(): number {
        return this.descriptor.timestamp;
    }

    get provider(): string {
        return this.descriptor.provider;
    }

    get authId(): string {
        return this.descriptor.authId;
    }

    get isTimedOut(): boolean {
        return Date.now() - this.descriptor.timestamp > 300000;
    }

}

export interface IAuthState {
    readonly timestamp: number;
    readonly authenticated: boolean;
    readonly userDetails?: IUserDetails;
}

export class AuthState implements IAuthState {

    private static readonly KEY_AUTH_STATE = "leasler.auth";

    static load(): AuthState {
        const stateTxt = localStorage.getItem(AuthState.KEY_AUTH_STATE);
        if (!stateTxt)
            return undefined;

        return new AuthState(JSON.parse(stateTxt).state);
    }

    static create(authenticated: boolean, userDetails?: IUserDetails): AuthState {
        return new AuthState({
            timestamp: Date.now(),
            authenticated: authenticated,
            userDetails: userDetails
        });
    }

    static store(state: IAuthState) {
        localStorage.setItem(AuthState.KEY_AUTH_STATE, JSON.stringify(state));
    }

    private constructor(private state: IAuthState) { }

    get timestamp(): number {
        return this.state.timestamp;
    }

    get authenticated(): boolean {
        return this.state.authenticated;
    }

    get userDetails(): IUserDetails {
        return this.state.userDetails;
    }

}

export interface ILoginState {
    readonly timestamp: number;
    readonly loggedIn: boolean;
    readonly account?: ILeaslerAccount;
}

export class LoginState implements ILoginState {

    private static readonly KEY_LOGIN_STATE = "leasler.login";

    static load(): LoginState {
        const stateTxt = localStorage.getItem(LoginState.KEY_LOGIN_STATE);
        if (!stateTxt)
            return undefined;

        return new LoginState(JSON.parse(stateTxt));
    }

    static create(loggedIn: boolean, acctState?: ILeaslerAccount): LoginState {
        return new LoginState({
            timestamp: Date.now(),
            loggedIn: loggedIn,
            account: acctState
        });
    }

    store() {
        localStorage.setItem(LoginState.KEY_LOGIN_STATE, JSON.stringify(this.state));
    }

    private constructor(private state: ILoginState) { }

    get timestamp(): number {
        return this.state.timestamp;
    }

    get loggedIn(): boolean {
        return this.state.loggedIn;
    }

    get account(): ILeaslerAccount {
        return this.state.account;
    }

}

export interface IUserDetails {
    authType: string;
    user: string;
    name: string;
    surname: string;
    email: string;
}

export interface IUserPrefs {
    lang: string;
}

export interface ILeaslerAccount {
    prefs: IUserPrefs;
}

export class UserPrefs implements IUserPrefs {

    private _lang: string;

    constructor(lang?: string) {
        this._lang = lang ? lang : 'ro';
    }

    get lang(): string {
        return this._lang;
    }

}