import { AS_CANCELLED, RawAccountStats } from './../public/model/account.model';
import { CacheService } from './cache.service';
import { PlatformService } from './platform.service';
import { AUTH_OAUTH, ILeaslerAccount } from './../model/login.model';
import { EV_TOPIC_LOGIN, EV_TOPIC_CONN } from './../model/events.model';
import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ILoginState, LoginState } from '../model/login.model';
import { AuthService } from './auth.service';
import { EventType } from '../model/events.model';
import { EventsService } from './events.service';
import { AWSService } from './aws.service';
import { RuntimeError } from '../model/errors.model';
import { TimeMachineService } from './time-machine.service';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    private _state: LoginState;

    constructor(
        private srvAuth: AuthService,
        private srvAWS: AWSService,
        private logger: NGXLogger,
        private events: EventsService,
        private nav: NavController,
        private srvPlatform: PlatformService,
        private srvCache: CacheService,
        private srvTime: TimeMachineService
    ) {
        this._state = LoginState.load();
        if (!this._state)
            this.updateLoginState(LoginState.create(false));

        this.logger.info(`Init login service (loggedIn: ${this._state.loggedIn})`, this._state.account);
        this.events.subscribe(
            EV_TOPIC_CONN,
            (event) => {
                if (event.type == EventType.CONNECTIVITY_CHANGE && (event.data as boolean) && this._state.loggedIn)
                    this.srvAuth.getCurrentAuthState(authState => this.updateLoginState(authState.authenticated ? this._state : LoginState.create(false)));
            }
        );
        if (this.srvPlatform.online && this._state.loggedIn)
            this.srvAuth.getCurrentAuthState(authState => this.updateLoginState(authState.authenticated ? this._state : LoginState.create(false)));
    }

    getCurrentLoginState(callback: (state: ILoginState) => void) {
        if (!this._state.loggedIn || !this.srvPlatform.online) {
            callback(this._state);
            if (!this._state.loggedIn)
                this.nav.navigateRoot("/pub");
        } else
            this.srvAuth.getCurrentAuthState(authState => {
                if (authState.authenticated)
                    callback(this._state);
                else {
                    this.updateLoginState(LoginState.create(false));
                    callback(this._state);
                    this.nav.navigateRoot("/pub");
                }
            });
    }

    leaslerSignup(
        onSuccess: () => void,
        onFailure: (err: any) => void
    ) {
        this.srvAuth.getCurrentAuthState(authState => {
            if (authState.authenticated)
                this.srvAWS.apiGwMakeRequest(
                    'PUT',
                    '/' + environment.apiGwProtoVerStr + '/user',
                    {},
                    {},
                    {},
                    () => onSuccess(),
                    (err) => onFailure(err)
                );
            else
                onFailure(new RuntimeError("NotAuthenticated", "User is not authenticated"));
        });
    }

    leaslerLogin(
        onSuccess: (state: ILoginState) => void,
        onWarn: (acctStats: RawAccountStats) => void,
        onFailure: (err: any) => void
    ) {
        this.srvAuth.getCurrentAuthState(authState => {
            if (authState.authenticated)
                this.srvAWS.apiGwMakeRequest(
                    'GET',
                    '/' + environment.apiGwProtoVerStr + '/user',
                    {},
                    {},
                    {},
                    (account: ILeaslerAccount) => {
                        this.updateLoginState(LoginState.create(true, account));
                        this.postEvent(EventType.LOGIN_SUCCESS, this._state);
                        let acctStats = account as unknown as RawAccountStats;
                        if (acctStats.accountState == AS_CANCELLED)
                            onWarn(acctStats)
                        else
                            onSuccess(this._state);
                    },
                    (err) => {
                        this.logger.error('Error starting leasler session', err);
                        this.postEvent(EventType.LOGIN_FAILURE, err);
                        onFailure(err);
                    })
            else
                onFailure(new RuntimeError("NotAuthenticated", "User is not authenticated"));
        });
    }

    leaslerAgreeTAC(
        onSuccess: () => void,
        onFailure: (err: any) => void
    ) {
        this.srvAuth.getCurrentAuthState(authState => {
            if (authState.authenticated)
                this.srvAWS.apiGwMakeRequest(
                    'POST',
                    '/' + environment.apiGwProtoVerStr + '/user',
                    {},
                    {},
                    {
                        agreeGTAC: true,
                        agreeCTAC: true
                    },
                    () => {
                        this.logger.info('Success: leaslerAgreeTAC()');
                        onSuccess();
                    },
                    (err) => {
                        this.logger.error('Error during leaslerAgreeTAC()', err);
                        onFailure(err);
                    }
                );
            else
                onFailure(new RuntimeError("NotAuthenticated", "User is not authenticated"));
        });
    }

    logout() {
        this.updateLoginState(LoginState.create(false));
        this.srvAuth.getCurrentAuthState(authState => {
            const authType = authState.authenticated ? authState.userDetails.authType : undefined;
            this.srvAuth.signOut();
            if (authType != AUTH_OAUTH)
                this.nav.navigateBack('/pub');
        });
    }

    private updateLoginState(state: LoginState) {
        if (state != this._state) {
            this.srvCache.clearAllCaches();
            this.srvTime.reset();
            this._state = state;
            state.store();
            this.logger.info(`Updated login state (loggedIn: ${this._state.loggedIn})`, this._state.account);
        }
    }


    private postEvent(id: number, data: any) {
        this.events.post(
            EV_TOPIC_LOGIN,
            {
                type: id,
                data: data
            }
        );
    }

}

