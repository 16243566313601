import { LocaleService } from './locale.service';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from "@angular/core";
import { EventType, EV_TOPIC_ACCT } from "../model/events.model";
import { EventsService } from "./events.service";
import { AlertController, NavController } from '@ionic/angular';
import { IAccountStats, WarnId, WARN_FIX_URL } from '../public/model/account.model';

@Injectable({
    providedIn: 'root'
})
export class WarnService {

    private static readonly KEY_ACCT_LAST_WARN = "leasler.acct.lastWarn";
    private static readonly ONE_HOUR = 3600;

    private lastRun = 0;
    private lastWarn = [0, 0, 0, 0];

    constructor(
        private srvEvents: EventsService,
        private alertCtl: AlertController,
        private srvLoc: LocaleService,
        private nav: NavController,
        private trans: TranslateService
    ) {
        const lastWarnTxt = localStorage.getItem(WarnService.KEY_ACCT_LAST_WARN);
        if (lastWarnTxt) {
            let lwStored = JSON.parse(lastWarnTxt);
            this.lastWarn = lwStored ? lwStored : [0, 0, 0, 0];
        }
    }

    init() {
        this.srvEvents.subscribe(EV_TOPIC_ACCT, ev => {
            let stats = ev.data as IAccountStats;
            let nowSecs = Math.floor(Date.now() / 1000);
            if (nowSecs - this.lastRun > 1200 && ev.type == EventType.ACCT_STATS_AVAIL) {
                this.lastRun = nowSecs;
                this.processWarnings(stats);
            }
        });
    }

    private processWarnings(stats: IAccountStats) {
        this.processWarning(WarnId.PMT_DECLINED, 2 * WarnService.ONE_HOUR, stats)
            .then(() => this.processWarning(WarnId.AGREE_TERMS, 24 * WarnService.ONE_HOUR, stats))
            .then(() => this.processWarning(WarnId.ACCT_DETAILS, 24 * WarnService.ONE_HOUR, stats))
            .then(() => this.processWarning(WarnId.PMT_DETAILS, 24 * WarnService.ONE_HOUR, stats))
            .catch(warnId => this.nav.navigateForward(WARN_FIX_URL[warnId]));
    }

    private async processWarning(warnId: number, warnIntervalSecs: number, stats: IAccountStats): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let nowSecs = Math.floor(Date.now() / 1000);
            if (!stats.hasWarning(warnId) || !stats.isWarnTermImminent(warnId) || nowSecs - this.getLastWarnSecs(warnId) < warnIntervalSecs || stats.isStateCancelled)
                resolve();
            else
                this.showWarning(warnId, stats, fixNow => {
                    if (fixNow) {
                        reject(warnId);
                    } else
                        resolve();
                });
        });
    }

    private async showWarning(warnId: number, stats: IAccountStats, callback?: (breakAway: boolean) => void) {
        await new Promise(r => setTimeout(r, 2000));
        let breakAway = false;
        const alert = await this.alertCtl.create({
            header: this.trans.instant("lbl.warning"),
            message: `${this.trans.instant("acctState.err" + warnId, { errCode: stats.pmtErrorCode })}. ${this.trans.instant("acctState.msgDate", { date: this.srvLoc.getShortFormattedDate(stats.getWarnTermDate(warnId)) })}`,
            buttons: [
                {
                    text: this.trans.instant("btn.ignore"),
                    role: 'cancel'
                },
                {
                    text: this.trans.instant("btn.fix"),
                    handler: () => { breakAway = true }
                }
            ],
            backdropDismiss: false
        });
        if (callback)
            alert.onDidDismiss().then(() => callback(breakAway));
        await alert.present().then(() => { this.touchLastWarnSecs(warnId) });
    }

    private getLastWarnSecs(warnId: number): number {
        return this.lastWarn[warnId];
    }

    private touchLastWarnSecs(warnId: number) {
        this.lastWarn[warnId] = Math.floor(Date.now() / 1000);
        localStorage.setItem(WarnService.KEY_ACCT_LAST_WARN, JSON.stringify(this.lastWarn));
    }

}
