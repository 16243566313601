import { ILoginState } from './../model/login.model';
import { Injectable } from '@angular/core';

import { LoginService } from './login.service';

@Injectable({
    providedIn: 'root'
})
export class LoggedInGuard  {

    constructor(private srvLogin: LoginService) { }

    canActivate(): Promise<boolean> {
        return new Promise<boolean>((resolve: (boolean) => void, reject) => {
            this.srvLogin.getCurrentLoginState(
                (state: ILoginState) => {
                    resolve(state.loggedIn);
                }
            );
        });
    }
}

