import { RuntimeError } from './../model/errors.model';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { TemplateSpec, RawTemplateSpec } from '../model/template.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TemplateService {

    private spec: TemplateSpec;
    private loaded: boolean;

    constructor(private http: HttpClient, private logger: NGXLogger) {
    }

    isLoaded(): boolean {
        return this.loaded;
    }

    getSpec(): Promise<TemplateSpec> {
        if (this.spec)
            return new Promise<TemplateSpec>((resolve) => resolve(this.spec));

        return new Promise<TemplateSpec>((resolve, reject) => {
            this.http.get<RawTemplateSpec>('/assets/json/templates.json').pipe(
                map<RawTemplateSpec, TemplateSpec>(raw => new TemplateSpec(raw))
            ).subscribe(
                spec => {
                    this.spec = spec;
                    this.loaded = true;
                    this.logger.info("Loaded template specs");
                    resolve(spec);
                },
                err => {
                    this.logger.error("Error loading template specs", err);
                    reject(err);
                }
            );
        });
    }

    getSpecInstant(): TemplateSpec {
        if (!this.loaded)
            throw new RuntimeError("TemplateSpecNotLoaded", "Templates not loaded");
        return this.spec;
    }

}
