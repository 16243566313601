import { AlertsService } from './services/alerts.service';
import { NGXLogger } from 'ngx-logger';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { PlatformService } from './services/platform.service';
import { TimeMachineView } from './tm-view';
import { TimeMachineService } from './services/time-machine.service';
import { EventsService } from './services/events.service';
import { EventType, EV_TOPIC_TM, EV_TOPIC_URL } from './model/events.model';
import { ModalService } from './services/modal.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {

  readonly S_RDY = '';

  platformState: string = 'RDY';
  isTmInPresent: boolean;
  tmBtnDisabled: boolean;

  private subs = new Subscription();

  constructor(
    public srvPlatform: PlatformService,
    private alerts: AlertsService,
    private srvTime: TimeMachineService,
    private srvEvents: EventsService,
    private srvMod: ModalService,
    private logger: NGXLogger
  ) {
  }

  ngOnInit() {
    this.srvPlatform.initPlatform().then(
      () => this.platformState = this.S_RDY,
      (err: any) => {
        this.logger.error("Platform initialization error", err);
        this.alerts.errorVerbose('appInit', err, true, () => { location.reload() });
      }
    );
    this.isTmInPresent = this.srvTime.offsetDays == 0;
    this.subs.add(this.srvEvents.subscribe(EV_TOPIC_TM, ev => {
      if (ev.type == EventType.TM_TIME_CHANGE)
        this.isTmInPresent = ev.data == 0;
    }));
    this.subs.add(this.srvEvents.subscribe(EV_TOPIC_URL, ev => {
      if (ev.type == EventType.URL_CHANGE)
        this.tmBtnDisabled = String(ev.data).startsWith("/priv/tabs/metrics");
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onShowTimeMachine() {
    this.srvMod.showModal(TimeMachineView, {});
  }

  onCloudClick() {
  }

  onAvatarClick() {
    /*
    this.srvLogin.getCurrentLoginState(
      (state: ILoginState) => {
        if (state.loggedIn)
          this.srvAlerts.alert(`${state.userDetails.name} ${state.userDetails.surname}`, state.userDetails.email)
      }
    );*/
  }

}
