import { WarnService } from './warn.service';
import { EventType, EV_TOPIC_CONN, EV_TOPIC_URL, EV_TOPIC_SCREEN } from './../model/events.model';
import { EventsService } from './events.service';
import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';
import { TemplateService } from './template.service';
import { SwUpdate } from '@angular/service-worker';
import { Platform, ToastController } from '@ionic/angular';
import { LogMonitorService } from './logmonitor.service';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from './locale.service';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { debounceTime } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PlatformService {

    private connected: boolean = false;
    private _width: number;
    private _height: number;
    private _prevUrl: string = '';
    private _url: string = '';
    private _darkMode: boolean;

    constructor(
        private platform: Platform,
        private srvTpl: TemplateService,
        private swUpdate: SwUpdate,
        private toastCtl: ToastController,
        private logmon: LogMonitorService,
        private trans: TranslateService,
        private srvLoc: LocaleService,
        private events: EventsService,
        private srvWarn: WarnService,
        private router: Router
    ) { }

    initPlatform(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.logmon.record();
            this.srvLoc.initLocale('ro', 'ro').subscribe(
                () => {
                    this.swUpdate.available.subscribe(async () => {
                        this.trans.get(["msg.updateAvl", "btn.restart"]).subscribe(async strings => {
                            const toast = await this.toastCtl.create({
                                message: strings["msg.updateAvl"],
                                position: 'bottom',
                                buttons: [{
                                    text: strings["btn.restart"],
                                    role: 'cancel'
                                }]
                            });

                            await toast.present();

                            toast
                                .onDidDismiss()
                                .then(() => this.swUpdate.activateUpdate())
                                .then(() => window.location.reload());
                        });
                    });

                    this._width = this.platform.width()
                    this._height = this.platform.height()
                    this.platform.resize.pipe(debounceTime(50)).subscribe(() => {
                        this._width = this.platform.width()
                        this._height = this.platform.height()
                        this.events.post(
                            EV_TOPIC_SCREEN,
                            {
                                type: EventType.SCREEN_RESIZE,
                                data: { w: this._width, h: this._height }
                            }
                        );
                    });

                    let dark = window.matchMedia('(prefers-color-scheme: dark)');
                    this._darkMode = dark.matches;
                    dark.addEventListener("change", e => {
                        this._darkMode = e.matches;
                        this.events.post(
                            EV_TOPIC_SCREEN,
                            {
                                type: EventType.SCREEN_DARKMODE,
                                data: e.matches
                            }
                        );
                    });

                    this._url = this.router.url;
                    this.router.events.subscribe((event: RouterEvent) => {
                        if (event && event instanceof NavigationEnd) {
                            this._prevUrl = this._url;
                            this._url = event.urlAfterRedirects;
                            this.events.post(
                                EV_TOPIC_URL,
                                {
                                    type: EventType.URL_CHANGE,
                                    data: this._url
                                }
                            );
                        }
                    });

                    Network.addListener('networkStatusChange', (status) => {
                        if (status.connected != this.connected) {
                            this.connected = status.connected;
                            this.events.post(
                                EV_TOPIC_CONN,
                                {
                                    type: EventType.CONNECTIVITY_CHANGE,
                                    data: this.connected
                                }
                            );
                        }
                    });

                    this.srvWarn.init();

                    Promise.all([this.srvTpl.getSpec(), Network.getStatus()]).then(
                        (res) => {
                            this.connected = res[1].connected;
                            resolve();
                        },
                        (err) => reject(err)
                    );
                },
                (err) => reject(err)
            );
        });
    }

    get width(): number {
        return this._width;
    }

    get height(): number {
        return this._height;
    }

    get url(): string {
        return this._url;
    }

    get prevUrl(): string {
        return this._prevUrl;
    }

    get online(): boolean {
        return this.connected;
    }

    get darkMode(): boolean {
        return this._darkMode;
    }

}

export function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};
