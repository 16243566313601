export const environment = {
  production: true,

  verStr: 'v0.18.1',

  awsRegion: 'eu-west-1',
  awsAppClientId: 'cfvpgf1bouq834nm00osakae',
  awsIdentityPoolId: 'eu-west-1:2b04411b-f32a-4e16-8c3b-6e7a64ebb626',
  awsUserPoolId: 'eu-west-1_V2DAUoSkO',

  apiGwHost: 'nfirglbs79.execute-api.eu-west-1.amazonaws.com',
  apiGwUrl: 'https://app.leasler.com',
  apiGwProtoVerStr: 'v1',

  oauthAppWebDomain: 'auth.leasler.com',
  oauthTokenScopesArray: ['openid', 'profile', 'email', 'aws.cognito.signin.user.admin'],
  oauthRedirectUriSignIn: 'https://app.leasler.com/pub/login',
  oauthRedirectUriSignOut: 'https://app.leasler.com/pub',

  facebookAppId: '598395757655963'
};
