import { LogMonitorService } from './logmonitor.service';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { LoginService } from './login.service';
import { PlatformService } from './platform.service';
import { AWSService } from './aws.service';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AlertsService {

    constructor(
        private alertCtl: AlertController,
        private trans: TranslateService,
        private srvLogMon: LogMonitorService,
        private srvLogin: LoginService,
        private srvPlatform: PlatformService,
        private srvAWS: AWSService
    ) { }

    async notice(notId: string, callback?: () => void) {
        await this.alert(this.trans.instant('lbl.notice'), this.trans.instant("not." + notId), false, callback);
    }

    async error(errId: string, reportErr: boolean = false, callback?: () => void, msgParams?: Object) {
        await this.alert(this.trans.instant('lbl.error'), this.trans.instant("err." + errId, msgParams), reportErr, callback);
    }

    async errorVerbose(errId: string, msg: string, reportErr: boolean = false, callback?: () => void, msgParams?: Object) {
        await this.alert(this.trans.instant('lbl.error'), this.trans.instant("err." + errId, msgParams) + ' -- ' + msg, reportErr, callback);
    }

    async warning(wrnId: string, callback?: () => void, msgParams?: Object) {
        await this.alert(this.trans.instant('lbl.warning'), this.trans.instant("wrn." + wrnId, msgParams), false, callback);
    }

    async warningVerbose(wrnId: string, msg: string, callback?: () => void, msgParams?: Object) {
        await this.alert(this.trans.instant('lbl.warning'), this.trans.instant("wrn." + wrnId, msgParams) + ' -- ' + msg, false, callback);
    }

    async confirm(confId: string, callback?: (confirmed: boolean) => void, msgParams?: Object) {
        await this.confirmation(this.trans.instant('lbl.warning'), this.trans.instant("conf." + confId, msgParams), callback);
    }

    async confirmVerbose(confId: string, msg: string, callback?: (confirmed: boolean) => void, msgParams?: Object) {
        await this.confirmation(this.trans.instant('lbl.warning'), this.trans.instant("conf." + confId, msgParams) + ' -- ' + msg, callback);
    }

    async confirmElemHistoDelete(headerLblId: string, confirmedHandler: (keepHisto: boolean) => void, cancelHandler?: () => void) {
        let confirmed = false;
        let keepHisto = true;
        const alert = await this.alertCtl.create({
            header: this.trans.instant(headerLblId),
            buttons: [
                {
                    text: this.trans.instant("btn.cancel"),
                    role: 'cancel',
                    handler: () => { }
                },
                {
                    text: this.trans.instant("btn.confirm"),
                    handler: (data) => {
                        if (data == 'del')
                            keepHisto = false;
                        confirmed = true;
                    }
                }
            ],
            inputs: [
                {
                    label: this.trans.instant('lbl.keepHisto'),
                    type: 'radio',
                    checked: true,
                    value: 'keep'
                },
                {
                    label: this.trans.instant('lbl.delHisto'),
                    type: 'radio',
                    value: 'del',
                    cssClass: 'radio-del'
                }
            ],
            cssClass: 'histo-confirm-alert'
        });
        alert.onDidDismiss().then(() => {
            if (confirmed)
                confirmedHandler(keepHisto);
            else if (cancelHandler)
                cancelHandler();
        });
        await alert.present();
    }

    async handleAPIError(err: any, genAlertId: string = 'commError', cvAlertId: string = 'cvError', callback?: () => void) {
        if (err.response && err.response.data) {
            if (err.response.data.code == 'ConstraintViolation')
                this.error(cvAlertId, false, callback);
            else if (err.response.data.code == 'ReadonlyAccount')
                this.error("roAccount", false, callback);
            else
                this.error(genAlertId, true, callback);
        } else
            this.error(genAlertId, true, callback);
    }

    private async alert(heading: string, message: string, reportErr: boolean = false, callback?: () => void) {
        const btns: any[] = reportErr && this.srvPlatform.online ? [{
            text: this.trans.instant("btn.reportErr"),
            handler: () => this.uploadLogs()
        }] : [];
        btns.push({ text: this.trans.instant("btn.ok") });
        const alert = await this.alertCtl.create({
            header: heading,
            message: message,
            buttons: btns
        });
        if (callback)
            alert.onDidDismiss().then(callback);
        await alert.present();
    }

    private uploadLogs() {
        this.srvLogin.getCurrentLoginState(
            (state) => {
                if (state.loggedIn) {
                    this.srvAWS.apiGwMakeRequest(
                        'PUT',
                        '/' + environment.apiGwProtoVerStr + '/log',
                        { 'Content-Type': 'text/plain' },
                        {},
                        this.srvLogMon.getLogStr(),
                        () => window.location.reload(),
                        err => window.location.reload()
                    );
                } else {
                }
            }
        );
    }

    private async confirmation(heading: string, message: string, callback?: (confirmed: boolean) => void) {
        let result = false;
        const alert = await this.alertCtl.create({
            header: heading,
            message: message,
            buttons: [
                {
                    text: this.trans.instant("btn.cancel"),
                    role: 'cancel',
                    handler: () => { }
                },
                {
                    text: this.trans.instant("btn.confirm"),
                    handler: () => { result = true; }
                }
            ]
        });
        if (callback)
            alert.onDidDismiss().then(() => callback(result));
        await alert.present();
    }

}
