import { PlatformService } from './platform.service';
import { AUTH_COGNITO, AUTH_OAUTH, AuthState, OAuthDescriptor, IAuthState, IUserDetails } from './../model/login.model';
import { EV_TOPIC_OAUTH } from './../model/events.model';
import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { AWSService } from './aws.service';
import { CognitoUserPool, CognitoUser, CognitoUserAttribute, CognitoUserSession, AuthenticationDetails, UserData, CognitoIdToken, ICognitoUserAttributeData, CognitoAccessToken, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { environment } from '../../environments/environment';
import { CognitoAuth, CognitoAuthSession } from 'amazon-cognito-auth-js';
import { ILoginCredentials } from '../model/login.model';
import { EventType } from '../model/events.model';
import { EventsService } from './events.service';
import { RuntimeError } from '../model/errors.model';
import { ConnectionStatus, Network } from '@capacitor/network';


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private _state: AuthState;

    private oauthDesc: OAuthDescriptor;
    private userPool: CognitoUserPool;
    private oauthObj: CognitoAuth;
    private cacheLoginCreds: ILoginCredentials;

    constructor(
        private srvAWS: AWSService,
        private events: EventsService,
        private srvPlatform: PlatformService,
        private logger: NGXLogger
    ) {
        this._state = AuthState.load();
        if (!this._state)
            this.updateAuthState(AuthState.create(false));

        this.logger.info(`Init auth service (authenticated: ${this._state.authenticated})`, this._state.userDetails);

        this.userPool = new CognitoUserPool({
            UserPoolId: environment.awsUserPoolId,
            ClientId: environment.awsAppClientId
        });

        this.oauthDesc = OAuthDescriptor.load();
        if (this.oauthDesc)
            this.oauthObj = this.createOAuthObj(this.oauthDesc.provider);
    }

    getCurrentAuthState(callback: (state: IAuthState) => void) {
        if (!this._state.authenticated || !this.srvPlatform.online) {
            callback(this._state);
        } else
            this.srvAWS.getAWSCredentialsStatus(
                () => callback(this._state),
                () => this.refreshAuthState().then(authenticated => {
                    if (authenticated)
                        callback(this._state);
                    else {
                        this.updateAuthState(AuthState.create(false));
                        callback(this._state);
                    }
                })
            );
    }

    getCurrentAuthStateImmediate(): IAuthState {
        return this._state;
    }

    registerCognitoUser(userDetails: IUserDetails, password: string, callback: (err, result) => void) {
        const userPool = this.userPool;
        var attributeList = [];

        const attEmail = new CognitoUserAttribute({
            Name: 'email',
            Value: userDetails.email
        });
        const attName = new CognitoUserAttribute({
            Name: 'given_name',
            Value: userDetails.name
        });
        const attSurname = new CognitoUserAttribute({
            Name: 'family_name',
            Value: userDetails.surname
        });

        attributeList.push(attEmail);
        attributeList.push(attName);
        attributeList.push(attSurname);

        userPool.signUp(userDetails.email, password, attributeList, null, callback);
    }

    confirmCognitoUser(userName: string, code: string, callback: (err, result) => void) {
        const userData = {
            Username: userName,
            Pool: this.userPool
        };
        let cognitoUser = new CognitoUser(userData);
        cognitoUser.confirmRegistration(code, true, callback);
    }

    resendAccountConfCode(userName: string, callback: (err, result) => void) {
        const userData = {
            Username: userName,
            Pool: this.userPool
        };
        let cognitoUser = new CognitoUser(userData);
        cognitoUser.resendConfirmationCode(callback);
    }

    getCognitoUser(): CognitoUser {
        return this.userPool.getCurrentUser();
    }

    cacheCognitoLoginCredentials(loginCreds: ILoginCredentials) {
        this.cacheLoginCreds = loginCreds;
    }

    isCredentialsCacheEmpty(): boolean {
        return this.cacheLoginCreds == undefined;
    }

    clearCachedCognitoLoginCredentials() {
        this.cacheLoginCreds = undefined;
    }

    authWithCognito(
        username: string,
        password: string,
        onSuccess: (state: IAuthState) => void,
        onFailure: (err: any) => void
    ) {
        const authData = {
            Username: username ? username : this.cacheLoginCreds.email,
            Password: password ? password : this.cacheLoginCreds.password,
        };
        const userData = {
            Username: authData.Username,
            Pool: this.userPool
        };
        let cognitoUser = new CognitoUser(userData);
        cognitoUser.authenticateUser(new AuthenticationDetails(authData), {
            onSuccess: (session: CognitoUserSession, userConfirmationNecessary?: boolean) => {
                let user = this.userPool.getCurrentUser();
                user.getSession((err, session: CognitoUserSession) => {
                    if (err)
                        onFailure(err);
                    else
                        user.getUserData((err: Error, result: UserData) => {
                            if (err)
                                onFailure(err);
                            else {
                                let idToken = session.getIdToken();
                                this.srvAWS.updateAWSIdentityCredentials(
                                    idToken.getJwtToken(),
                                    () => {
                                        this.updateAuthState(AuthState.create(true, this.extractUserDetails(AUTH_COGNITO, idToken, result.UserAttributes)));
                                        onSuccess(this._state);
                                    },
                                    (err: any) => {
                                        this.logger.error('Cognito authentication error', err);
                                        onFailure(err);
                                    }
                                );
                            }
                        });
                });
            },
            onFailure: err => onFailure(err),
            newPasswordRequired: () => onFailure(new RuntimeError('newPasswordRequired', 'New password required'))
        });
    }

    forgotCognitoPassword(username: string, onSuccess: (data: any) => void, onFailure: (err: any) => void) {
        const userData = {
            Username: username,
            Pool: this.userPool
        };
        let cognitoUser = new CognitoUser(userData);
        cognitoUser.forgotPassword({
            onSuccess: onSuccess,
            onFailure: onFailure
        });
    }

    resetCognitoPassword(
        username: string,
        password: string,
        code: string,
        onSuccess: () => void,
        onFailure: (err: any) => void
    ) {
        const userData = {
            Username: username,
            Pool: this.userPool
        };
        let cognitoUser = new CognitoUser(userData);
        cognitoUser.confirmPassword(code, password, {
            onSuccess: onSuccess,
            onFailure: onFailure
        });
    }

    isValidOAuthInProgress(authId: string): boolean {
        return this.oauthDesc && !this.oauthDesc.isTimedOut && this.oauthDesc.authId == authId;
    }

    parseOAuthAttempt(url: string) {
        if (this.oauthObj)
            this.oauthObj.parseCognitoWebResponse(url);
    }

    authWithOAuth(provider: string) {
        this.updateOAuthDescriptor(OAuthDescriptor.create(provider));
        this.oauthObj = this.createOAuthObj(this.oauthDesc.provider);
        this.oauthObj.setState(this.oauthDesc.authId);
        this.oauthObj.getSession();
    }

    signOut() {
        let user = this.userPool.getCurrentUser();
        if (user)
            user.signOut();
        if (this.oauthObj) {
            this.oauthObj.signOut();
            this.oauthObj.clearCachedTokensScopes();
        }
        this.updateOAuthDescriptor(undefined);
        this.srvAWS.clearAWSCredentials();
        this.cacheLoginCreds = undefined;
        this.updateAuthState(AuthState.create(false));
    }

    private refreshAuthState(): Promise<boolean> {
        return new Promise<boolean>((resolve: (authenticated: boolean) => void) => {
            let user = this.userPool.getCurrentUser();
            if (!user)
                resolve(false);
            else
                user.getSession((err, session: CognitoUserSession) => {
                    if (err)
                        resolve(false);
                    else
                        this.srvAWS.updateAWSIdentityCredentials(
                            session.getIdToken().getJwtToken(),
                            () => resolve(true),
                            () => resolve(false)
                        );
                });
        });
    }

    private extractUserDetails(authType: string, cognitoIdToken: CognitoIdToken, attrs: ICognitoUserAttributeData[]): IUserDetails {
        let result: IUserDetails = {
            user: cognitoIdToken.decodePayload()['cognito:username'],
            name: '',
            surname: '',
            email: '',
            authType: authType
        };
        attrs.forEach(val => {
            switch (val.Name) {
                case 'given_name': {
                    result.name = val.Value;
                    break;
                }
                case 'family_name': {
                    result.surname = val.Value;
                    break;
                }
                case 'email': {
                    result.email = val.Value;
                    break;
                }
            }
        });
        return result;
    }

    private createOAuthObj(provider: string): CognitoAuth {
        let result = new CognitoAuth({
            ClientId: environment.awsAppClientId,
            AppWebDomain: environment.oauthAppWebDomain,
            TokenScopesArray: environment.oauthTokenScopesArray,
            RedirectUriSignIn: environment.oauthRedirectUriSignIn,
            RedirectUriSignOut: environment.oauthRedirectUriSignOut,
            IdentityProvider: provider,
            UserPoolId: environment.awsUserPoolId,
            AdvancedSecurityDataCollectionFlag: false
        });
        result.useCodeGrantFlow();
        result.userhandler = {
            onSuccess: (result: CognitoAuthSession) => {
                new CognitoUserSession({
                    IdToken: new CognitoIdToken({ IdToken: result.getIdToken().getJwtToken() }),
                    AccessToken: new CognitoAccessToken({ AccessToken: result.getAccessToken().getJwtToken() }),
                    RefreshToken: new CognitoRefreshToken({ RefreshToken: result.getRefreshToken().getToken() })
                });
                let user = this.userPool.getCurrentUser();
                user.getSession((err, session: CognitoUserSession) => {
                    if (err) {
                        this.logger.error('Error getting cognito session', err);
                        this.updateAuthState(AuthState.create(false));
                        this.postOAuthEvent(EventType.OAUTH_FAILURE, err);
                    } else
                        user.getUserData((err: Error, result: UserData) => {
                            if (err) {
                                this.logger.error('Error getting user data', err);
                                this.updateAuthState(AuthState.create(false));
                                this.postOAuthEvent(EventType.OAUTH_FAILURE, err);
                            } else {
                                let idToken = session.getIdToken();
                                this.srvAWS.updateAWSIdentityCredentials(
                                    idToken.getJwtToken(),
                                    () => {
                                        this.updateAuthState(AuthState.create(true, this.extractUserDetails(AUTH_OAUTH, idToken, result.UserAttributes)));
                                        this.logger.info('OAuth authentication successful', this._state);
                                        this.postOAuthEvent(EventType.OAUTH_SUCCESS, this._state.userDetails);
                                    },
                                    (err: any) => {
                                        this.logger.error('Error updating AWS crtedentials', err);
                                        this.updateAuthState(AuthState.create(false));
                                        this.postOAuthEvent(EventType.OAUTH_FAILURE, err);
                                    }
                                );
                            }
                        });
                });

            },
            onFailure: (err) => {
                this.logger.error('Error during OAuth process', err);
                this.postOAuthEvent(EventType.OAUTH_FAILURE, err)
            }
        }
        return result;
    }

    private updateAuthState(state: AuthState) {
        if (state != this._state) {
            this._state = state;
            AuthState.store(this._state);
            this.logger.info(`Updated auth state (authenticated: ${this._state.authenticated})`, this._state.userDetails);
        }
    }

    private async getNetworkStatus(): Promise<ConnectionStatus> {
        return await Network.getStatus();
    }

    private updateOAuthDescriptor(descriptor: OAuthDescriptor) {
        this.oauthDesc = descriptor;
        OAuthDescriptor.store(this.oauthDesc);
    }

    private postOAuthEvent(id: number, data: any) {
        this.events.post(
            EV_TOPIC_OAUTH,
            {
                type: id,
                data: data
            }
        );
    }

}

