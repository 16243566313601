import { IRuntimeEvent, EV_TOPIC_LOGIN, EV_TOPIC_OAUTH, EV_TOPIC_URL, EV_TOPIC_CONN, EV_TOPIC_PICSRV, EV_TOPIC_TM, EV_TOPIC_SCREEN, EV_TOPIC_ACCT, EV_TOPIC_PROPSRV } from './../model/events.model';
import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EventsService {

    private topics: { [name: string]: Subject<IRuntimeEvent> } = {};

    constructor() {
        this.topics[EV_TOPIC_LOGIN] = new Subject<IRuntimeEvent>();
        this.topics[EV_TOPIC_OAUTH] = new Subject<IRuntimeEvent>();
        this.topics[EV_TOPIC_SCREEN] = new Subject<IRuntimeEvent>();
        this.topics[EV_TOPIC_URL] = new Subject<IRuntimeEvent>();
        this.topics[EV_TOPIC_CONN] = new Subject<IRuntimeEvent>();
        this.topics[EV_TOPIC_PICSRV] = new Subject<IRuntimeEvent>();
        this.topics[EV_TOPIC_TM] = new Subject<IRuntimeEvent>();
        this.topics[EV_TOPIC_ACCT] = new Subject<IRuntimeEvent>();
        this.topics[EV_TOPIC_PROPSRV] = new Subject<IRuntimeEvent>();
    }

    subscribe(topic: string, handler: (event: IRuntimeEvent) => void): Subscription {
        const sub = this.topics[topic];
        return sub ? sub.asObservable().subscribe(handler) : undefined;
    }

    post(topic: string, event: IRuntimeEvent) {
        const sub = this.topics[topic];
        if (sub)
            sub.next(event);
    }

}
