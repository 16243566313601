<ion-header>
    <ion-toolbar>
        <ion-title translate>tm.title</ion-title>
        <ion-buttons slot="end">
            <ion-button color="primary" (click)="onClose()" translate>btn.ok</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content [scrollY]="false">
    <div class="view-content">
        <div class="tm-disp">
            <div [ngClass]="{'disp-line': true, 'present': isTmPresent}">
                <span>* </span>
                <span>{{tmTenseLbl | translate | uppercase}}</span>
                <span> *</span>
            </div>
            <div [ngClass]="{'disp-line': true, 'present': isTmPresent}">
                {{tmDateFmtd}}
            </div>
        </div>

        <div class="tm-control">
            <ion-range [min]="minYear" [max]="maxYear" [value]="valYear" color="primary"
                (ionChange)="onYearChange($event)">
                <ion-note slot="start" size="small" translate>tm.year</ion-note>
            </ion-range>
            <ion-range min="0" max="11" [value]="valMonth" color="primary" (ionChange)="onMonthChange($event)">
                <ion-note slot="start" size="small" translate>tm.month</ion-note>
            </ion-range>
            <ion-range min="1" [max]="maxDay" [value]="valDay" color="primary" (ionChange)="onDayChange($event)">
                <ion-note slot="start" size="small" translate>tm.dayOfMonth</ion-note>
            </ion-range>
        </div>

        <div class="tm-buttons">
            <div>
                <ion-button color="success" translate [disabled]="isTmPresent" (click)="onReset()">btn.reset
                </ion-button>
            </div>
        </div>
    </div>
</ion-content>