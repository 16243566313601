import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    private keyListener: (event: KeyboardEvent) => void = (event) => {
        if (this.lastModal && event.key == "Escape")
            this.dismissModal(undefined);
        else
            event.preventDefault();
        event.stopPropagation();
    }

    private modals: HTMLIonModalElement[] = [];
    private lastModal: HTMLIonModalElement = undefined;

    constructor(private mc: ModalController) {
        document.addEventListener("keyup", this.keyListener);
    }

    async showModal(pageComponentClass, data: any, callback?: (result) => void, cssClass?: string) {
        this.lastModal = await this.mc.create({
            component: pageComponentClass,
            componentProps: data,
            cssClass: cssClass,
            presentingElement: this.lastModal,
            showBackdrop: true,
            backdropDismiss: false,
            keyboardClose: false
        });
        this.lastModal.onDidDismiss().then(result => {
            if (callback)
                callback(result);
        });
        this.modals.push(this.lastModal);
        return await this.lastModal.present().then(() => this.lastModal.focus());
    }

    dismissModal(data?: object, callback?: () => void) {
        if (this.modals.length == 0)
            return;
        this.lastModal.dismiss(data).then(() => {
            this.modals.pop();
            this.lastModal = this.modals.length > 0 ? this.modals[this.modals.length - 1] : undefined;
            if (callback)
                callback();
        });
    }
}


