import { EventType } from './../model/events.model';
import { Injectable } from '@angular/core';
import { EV_TOPIC_TM } from '../model/events.model';
import { EventsService } from './events.service';
import { LocaleService } from './locale.service';

@Injectable({
    providedIn: 'root'
})
export class TimeMachineService {

    private tmOffsetDays: number;

    constructor(
        private srvLoc: LocaleService,
        private srvEvents: EventsService
    ) {
        let persisted = TmState.load();
        this.tmOffsetDays = persisted && !persisted.isExpired ? persisted.tmOffsetDays : 0;
    }

    get offsetDays(): number {
        return this.tmOffsetDays;
    }

    reset() {
        this.changeOffsetDays(0);
    }

    getTmTimeRefTz(): Date {
        let timeRefTz = this.srvLoc.getTimeRefTz();

        timeRefTz.setDate(timeRefTz.getDate() - this.tmOffsetDays);
        return timeRefTz;
    }

    getTmDbTimeStamp(): number {
        return Math.floor(this.getTmTimeRefTz().getTime() / 1000);
    }

    changeOffsetDays(newOffsetDays: number) {
        if (newOffsetDays != this.tmOffsetDays) {
            this.tmOffsetDays = newOffsetDays;
            this.persistState();
            this.srvEvents.post(EV_TOPIC_TM, {
                type: EventType.TM_TIME_CHANGE,
                data: this.tmOffsetDays
            })
        }
    }

    changeDate(newDateRefTz: Date) {
        let todayRefTz = this.srvLoc.getTimeRefTz();
        todayRefTz.setHours(0, 0, 0, 0);
        newDateRefTz.setHours(0, 0, 0, 0);
        this.changeOffsetDays(Math.floor((todayRefTz.getTime() - newDateRefTz.getTime() /*+ Math.abs(todayRefTz.getTimezoneOffset() - newDateRefTz.getTimezoneOffset()) * 60000*/) / 86400000));
    }

    private persistState() {
        TmState.store(this.tmOffsetDays);
    }

}

interface ITmState {
    tmOffsetDays: number;
    timestamp: number;
}

export class TmState implements ITmState {

    private static readonly KEY_TM_STATE = "leasler.tm";

    static load(): TmState {
        const stateTxt = localStorage.getItem(TmState.KEY_TM_STATE);
        if (!stateTxt)
            return undefined;

        return new TmState(JSON.parse(stateTxt));
    }

    static store(tmOffsetDays: number) {
        localStorage.setItem(TmState.KEY_TM_STATE, JSON.stringify({
            tmOffsetDays: tmOffsetDays,
            timestamp: Date.now()
        }));
    }

    private constructor(private state: ITmState) { }

    get timestamp(): number {
        return this.state.timestamp;
    }

    get tmOffsetDays(): number {
        return this.state.tmOffsetDays;
    }

    get isExpired(): boolean {
        const delta = Date.now() - this.state.timestamp;
        return delta > 3600000;
    }

}
