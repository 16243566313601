import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LMissingTransHandler } from '../handlers/translation.handler';
import { tap } from 'rxjs/operators';
import { CoordData, MapConf } from '../privileged/model/geolocation.model';

@Injectable({
    providedIn: 'root'
})
export class LocaleService {

    locale: string;
    dfltMapConf: MapConf;
    monthNames: string[];
    monthNamesShort: string[];
    //    refTzOffset: number;
    //    refTzDSTOffset: number;

    constructor(private trans: TranslateService) { }

    initLocale(lang: string, refTz: string): Observable<any> {
        // check refTz, but for now, assume 'ro'
        //        this.refTzOffset = -120;
        //        this.refTzDSTOffset = -180;

        /*const browserLang = this.trans.getBrowserLang();
        this.trans.use(browserLang.match(/en|fr/) ? browserLang : 'en');*/
        this.trans.addLangs(['ro']);
        this.trans.setDefaultLang('ro');
        this.trans.missingTranslationHandler = new LMissingTransHandler();

        this.locale = "ro";
        this.dfltMapConf = {
            label: "România",
            coord: new CoordData(false, 45.9852129, 24.6859225),
            zoomLvl: 5,
            showMarker: false
        };

        return this.trans.use(this.locale).pipe(
            tap(dictionary => {
                this.monthNames = [];
                this.monthNamesShort = [];
                for (var idx = 0; idx < 12; idx++) {
                    this.monthNames.push(dictionary['months']['l'][String(idx)]);
                    this.monthNamesShort.push(dictionary['months']['s'][String(idx)]);
                }
            })
        );
    }

    isDSTObserved(dateLocalTz: Date): boolean {
        let jan = new Date(dateLocalTz.getFullYear(), 0, 1).getTimezoneOffset();
        let jul = new Date(dateLocalTz.getFullYear(), 6, 1).getTimezoneOffset();
        return Math.max(jan, jul) != dateLocalTz.getTimezoneOffset();
    }

    getDateFormat(): string {
        // change for othger locales
        return 'DD MMMM YYYY';
    }

    getAppleFormattedDate(date: Date, defaultFmt: (date: Date) => string): string {
        let d = new Date(date);
        let now = new Date();
        let nowMil = now.getTime();
        let dMil = d.getTime();
        if (Math.abs(nowMil - dMil) >= 172800000)
            return defaultFmt(date);
        if (now.getDate() == d.getDate())
            return this.trans.instant("lbl.today");
        if (nowMil >= dMil) {
            // d is in the past
            now.setDate(now.getDate() - 1);
            now.setHours(0, 0, 0, 0);
            return dMil >= now.getTime() ? this.trans.instant("lbl.yesterday") : defaultFmt(date);
        } else {
            // d is in the future
            now.setDate(now.getDate() + 1);
            now.setHours(23, 59, 59, 999);
            return dMil < now.getTime() ? this.trans.instant("lbl.tomorrow") : defaultFmt(date);
        }
    }

    getNumericDate(date: Date): string {
        return `${("" + date.getDate()).padStart(2, "0")}.${("" + (date.getMonth() + 1)).padStart(2, "0")}.${date.getUTCFullYear()}`;
    }

    getFormattedDate(date: Date): string {
        return `${date.getDate()} ${this.monthNames[date.getMonth()]} ${date.getFullYear()}`;
    }

    getShortFormattedDate(date: Date): string {
        return `${date.getDate()} ${this.monthNamesShort[date.getMonth()]} ${date.getFullYear()}`;
    }

    getFormattedCurrency(amount: number, currency: string): string {
        return amount.toLocaleString(this.locale, { style: "currency", currency: currency });
    }

    getTimeLocalTz(): Date {
        return new Date();
    }

    getDateLocalTz(offsetDays?: number): Date {
        let res = new Date();
        if (offsetDays)
            res.setDate(res.getDate() + offsetDays);
        res.setHours(0, 0, 0, 0);
        return res;
    }

    getTimeRefTz(): Date {
        let now = new Date();
        return new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    }

    local2RefTz(local: Date): Date {
        return new Date(local.getTime() - local.getTimezoneOffset() * 60000);
    }

    ref2LocalTz(refTime: Date): Date {
        return new Date(refTime.getTime() + refTime.getTimezoneOffset() * 60000);
    }

    dateLocal2DBTimeStamp(localTime: Date): number {
        //local TZ to ref TZ
        let resMillis = localTime.getTime() - localTime.getTimezoneOffset() * 60000;
        return Math.floor(resMillis / 1000);
    }

    dbTimeStamp2DateLocal(dbTimeStamp: number): Date {
        //ref TZ to local TZ
        let timeStampRef = new Date(dbTimeStamp * 1000);
        return new Date(timeStampRef.getTime() + timeStampRef.getTimezoneOffset() * 60000);
    }

    isSameDate(time1: Date, time2: Date): boolean {
        return time1.getFullYear() == time2.getFullYear() &&
            time1.getMonth() == time2.getMonth() &&
            time1.getDate() == time2.getDate();
    }

}
